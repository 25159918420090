import sotre from "@state/store"

// error pages
const errorPagesRoutes = [
    {
        path: '/404',
        name: '404',
        component: require('@views/pages/error/error-404').default,
        // Allows props to be passed to the 404 page through route
        // params, such as `resource` to define what wasn't found.
        props: true,
    },
    // Redirect any unmatched routes to the 404 page. This may
    // require some server configuration to work in production:
    // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
    {
        path: '*',
        redirect: '404',
    },
]


const authRoutes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {

                console.log('login user:' + sotre.state.loginUser)

                // If the user is already logged in
                if (sotre.state.loginUser) {
                    // Redirect to the home page instead
                    next({name: 'index'})
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('@views/pages/account/register')
    },
    {
        path: '/resetpassword',
        name: 'register',
        component: () => import('@views/pages/account/resetpassword')
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('@views/pages/account/logout')
    },
    {
        path: '/pay/alicallback',
        name: 'alicallback',
        component: () => import('@views/pages/pay/CallBack.vue')
    }
]

// dashboard
const dashboardRoutes = [
    {
        path: '/',
        component: () => import('@views/pages/courses/index'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (sotre.state.loginUser) {
                    // Redirect to the home page instead
                    next()
                } else {
                    // Continue to the login page
                    next({name: 'login'})
                }
            },
        },
    }
]

// courses
const coursesRoutes = [
    {
        name: 'courses',
        path: '/courses',
        // create a container component
        component: {
            render(c) {
                return c('router-view')
            },
        },
        children: [
            {
                path: '/',
                name: 'index',
                component: () => import('@views/pages/courses/index'),
            },
            {
                path: 'details',
                name: 'details',
                component: () => import('@views/pages/courses/details'),
            },
            {
                path: 'view',
                name: 'view',
                component: () => import('@views/pages/courses/view'),
            },
            {
                path: 'vnc',
                name: 'vnc',
                component: () => import('@views/pages/courses/vnc'),
            },
            {
                path: 'rdp',
                name: 'rdp',
                component: () => import('@views/pages/courses/rdp'),
            },
            {
                path: 'pdf',
                name: 'pdf',
                component: () => import('@views/pages/courses/pdf'),
            }
        ],
    },
]

// card
const cardRoutes = [
    {
        name: 'card',
        path: '/card',
        // create a container component
        component: {
            render(c) {
                return c('router-view')
            },
        },
        children: [
            {
                path: '/',
                name: 'index',
                component: () => import('@views/pages/card/index'),
            }
        ],
    },
]

// cert
// const certRoutes = [
//     {
//         name: 'cert',
//         path: '/cert',
//         // create a container component
//         component: {
//             render(c) {
//                 return c('router-view')
//             },
//         },
//         children: [
//             {
//                 path: '/',
//                 name: 'index',
//                 component: () => import('@views/pages/cert/index'),
//             },
//             {
//                 path: 'details',
//                 name: 'details',
//                 component: () => import('@views/pages/cert/details'),
//             }
//         ],
//     },
// ]

// order
const orderRoutes = [
    {
        name: 'order',
        path: '/order',
        // create a container component
        component: {
            render(c) {
                return c('router-view')
            },
        },
        children: [
            {
                path: 'add',
                name: 'add',
                component: () => import('@views/pages/order/add'),
            },
            {
                path: 'details',
                name: 'details',
                component: () => import('@views/pages/order/details'),
            },
            {
                path: 'list',
                name: 'list',
                component: () => import('@views/pages/order/list'),
            }
        ],
    },
]


// my
const myRoutes = [
    {
        name: 'my',
        path: '/my',
        // create a container component
        component: {
            render(c) {
                return c('router-view')
            },
        },
        children: [
            {
                path: 'index',
                name: 'index',
                component: () => import('@views/pages/my/index'),
            }
        ],
    },
]


const authProtectedRoutes = [
    ...dashboardRoutes,
    ...coursesRoutes,
    ...cardRoutes,
    ...orderRoutes,
    ...myRoutes
]

const allRoutes = [...authRoutes, ...authProtectedRoutes, ...errorPagesRoutes]

export {allRoutes, authProtectedRoutes}
