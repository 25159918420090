import Vue from 'vue'
import App from './App.vue'
import router from '@router'

import VueRouter from 'vue-router'
import axios from './axios'
import ElementUI from 'element-ui'

import appConfig from '@src/app.config'

import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue'
import 'element-ui/lib/theme-chalk/index.css'
import '@iconscout/unicons/css/line.css'
import '@assets/css/main.css'

Vue.config.productionTip = true

Vue.prototype.$axios = axios

Vue.prototype.staticImage = appConfig.staticImage

Vue.use(VueRouter)
Vue.use(ElementUI)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
    return originalReplace.call(this, location).catch(err => err)
}

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
