import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        loginUser: false
    },
    mutations: {
        login (state) {
            state.loginUser = true
        },
        logout (state) {
            state.loginUser = false
        }
    }
})

export default store