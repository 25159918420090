<template>
<h1>页面不存在</h1>
</template>

<script>
export default {
name: "error-404"
}
</script>

<style scoped>

</style>