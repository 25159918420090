// 1. 引入
import axios from 'axios'
import {Message} from 'element-ui'
import router from '@router'

import store from '@state/store'

import appConfig from '@src/app.config'

axios.defaults.withCredentials = true

// 2. 创建实例
const instance = axios.create({
    baseURL: appConfig.apiUrl,
    timeout: 100000
})

instance.interceptors.response.use(
    response => {
        return response
    }, function (error) {

        if (error.response && error.response.status === 401) {
            store.commit('logout')
            router.replace({
                path: '/login'
            })
        } else {
            Message({
                message: error.message,
                type: 'error',
                duration: 5 * 1000
            })
        }

        return Promise.reject(error)   // 返回接口返回的错误信息
    }
)

// 4. 导出
export default instance
